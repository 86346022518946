import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../images/logo.png";

const Footer = (props) => {
  return (
    <div className="tp-site-footer text-center">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="footer-image">
              <Link className="logo" to="/">
                <img src={Logo} alt="" />
              </Link>
            </div>
          </div>
          <div className="col-12">
            <div className="link-widget">
              <ul>
                <li>
                  <a
                    href="https://www.facebook.com/profile.php?id=100001603213379"
                    target="_blank"
                  >
                    <i className="ti-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="https://t.me/anvar_obbo" target="_blank">
                    <i className="ti-mobile"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/anvarjon_khalikov/"
                    target="_blank"
                  >
                    <i className="ti-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-12">
            <div className="copyright">
              <p>© {new Date().getFullYear()} Все права защищены</p>
              Веб-сайт создан компанией{" "}
              <a href="https://www.ithink.uz/" target="_blank">
                ITHINK
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
