import React from "react";
import aImg from "../../images/about/img-1.png";

const About = (props) => {
  return (
    <section className="tf-about-section section-padding">
      <div className="container">
        <div className="tf-about-wrap">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="tf-about-img">
                <img src={aImg} alt="" />
                <div className="tf-about-img-text">
                  <div className="tf-about-icon">
                    <h3>10+</h3>
                    <span>лет опыта</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="tf-about-text">
                <small>Обо Мне</small>
                <h2>
                  Создаю эффективные стратегии для развития вашего бизнеса
                </h2>
                <h5>Эксперт по систематизации бизнес-проектов</h5>
                <p>
                  С 2014 года – успешный предприниматель, стартап-менеджер и
                  бизнес-практик. Участник многих международных конференций и
                  семинаров в Турции, Германии, США, Китай, ОАЭ, России,
                  Казахстане, Узбекистане, Кыргызстане и Таджикистане.
                </p>

                <div className="tf-funfact">
                  <div className="tf-funfact-item">
                    <h3>
                      <span>20</span>+
                    </h3>
                    <p>Проекты</p>
                  </div>
                  <div className="tf-funfact-item">
                    <h3>
                      <span>52</span>+
                    </h3>
                    <p>Награды</p>
                  </div>
                  <div className="tf-funfact-item">
                    <h3>
                      <span>100</span>+
                    </h3>
                    <p>Ученики</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="visible-rotate-text">
        <h1>Обо Мне</h1>
      </div>
    </section>
  );
};

export default About;
