import React from "react";
import himg from "../../images/slider/right-img.png";
import { Link } from "react-scroll";

const Hero = () => {
  return (
    <section className="tp-hero-section-1">
      <div className="container">
        <div className="row">
          <div className="col col-xs-7 col-lg-7">
            <div className="tp-hero-section-text">
              <div className="tp-hero-title">
                <h2>Анвар Халиков</h2>
              </div>
              <div className="tp-hero-sub">
                <p>Предприниматель — бизнес практик</p>
              </div>
              <div className="btns">
                <Link
                  activeClass="active"
                  to="contact"
                  spy={true}
                  smooth={true}
                  duration={500}
                  offset={-95}
                  className="theme-btn"
                >
                  Связаться
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="right-vec">
        <div className="right-img">
          <img src={himg} alt="" />
        </div>
      </div>
      <div className="social-link">
        <ul>
          <li>
            <a
              href="https://www.facebook.com/profile.php?id=100001603213379"
              target="_blank"
            >
              Facebook
            </a>
          </li>
          <li>
            <a href="https://t.me/anvar_obbo" target="_blank">
              Telegram
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/anvarjon_khalikov/"
              target="_blank"
            >
              Instagram
            </a>
          </li>
        </ul>
      </div>
      <div className="visible-text">
        <h1>Business</h1>
      </div>
    </section>
  );
};

export default Hero;
