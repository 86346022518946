// images
import blogImg1 from "../images/blog/img-1.jpg";
import blogImg3 from "../images/blog/img-3.jpg";
import blogImg2 from "../images/blog/img-2.jpg";
import blogSingleImg1 from "../images/blog-details/img-1.jpg";
import blogSingleImg3 from "../images/blog-details/img-3.jpg";

const blogs = [
  {
    id: "1",
    title: "Мой путь к предпринимательству",
    screens: blogImg1,
    description: "Как я преодолел трудности и достиг успеха.",
    author: "Анвар Халиков",
    thumb: "Designer",
    create_at: "12 Янв, 2024",
    blogSingleImg: blogSingleImg1,
    comment: "35",
    blClass: "format-standard-image",
    link: "https://www.youtube.com/embed/SNFKK-ewT3U?si=eeIsj-1woZ170HSY",
  },
  {
    id: "2",
    title: "OBBO Первый Маркетплейс в Таджикистане",
    screens: blogImg1,
    description: "С маркетплейсом ОВВО покупки стали удобнее!",
    author: "OBBO Marketplace",
    thumb: "Designer",
    create_at: "8 Янв, 2024",
    blogSingleImg: blogSingleImg1,
    comment: "35",
    blClass: "format-standard-image",
    link: "https://www.youtube.com/embed/SHTAHfMIKuk?si=Sbr-HAW0ySW3kFf2",
  },
  {
    id: "3",
    title: "Кредит Маркет",
    screens: blogImg3,
    description: "Кредит Маркет - Открываем доступ к вашей мечте сегодня!",
    author: "Kredit Market",
    thumb: "Designer",
    create_at: "3 Янв, 2024",
    blogSingleImg: blogSingleImg1,
    comment: "35",
    blClass: "format-standard-image",
    link: "https://www.youtube.com/embed/N6jdFvA9orE?si=Rq8LafLBiqEojLlT",
  },
  {
    id: "4",
    title: "Наставник Саидмурод Раджабович Давлатов",
    screens: blogImg3,
    description:
      "Мудрый наставник и настоящий эксперт своего дела. Его мудрость и профессионализм вдохновляют меня на новые свершения и помогают преодолевать любые трудности.",
    author: "Анвар Халиков",
    thumb: "Fasion",
    create_at: "3 Май, 2022",
    blogSingleImg: blogSingleImg3,
    comment: "95",
    blClass: "format-video",
    link: "",
  },
  {
    id: "5",
    title: "Встреча с Маргуланом Калиевичем Сейсембаевым",
    screens: blogImg1,
    description:
      "Идейный и духовный вдохновитель, бизнес-коуч с уникальным опытом. Всегда рад нашим встречам и бриффингам.",
    author: "Анвар Халиков",
    thumb: "Fasion",
    create_at: "3 Дек, 2022",
    blogSingleImg: blogSingleImg3,
    comment: "95",
    blClass: "format-video",
    link: "",
  },
  {
    id: "6",
    title: "Встреча с Игорем Борисовичем Манном",
    screens: blogImg2,
    description:
      "Наставник и крутой эксперт в маркетинге. Он обладает уникальными знаниями и опытом, которые помогают мне развиваться в этой области.",
    author: "Анвар Халиков",
    thumb: "Fasion",
    create_at: "23 Окт, 2022",
    blogSingleImg: blogSingleImg3,
    comment: "95",
    blClass: "format-video",
    link: "",
  },
  {
    id: "7",
    title: "OBBO & Arvand",
    screens: blogImg1,
    description: "OBBO & Arvand\n",
    author: "OBBO Marketplace",
    thumb: "Designer",
    create_at: "25 Дек, 2023",
    blogSingleImg: blogSingleImg1,
    comment: "35",
    blClass: "format-standard-image",
    link: "https://www.youtube.com/embed/n_hrmFJe_80?si=CGvc5ExorruwMRqe",
  },
  {
    id: "8",
    title: "OBBO Покупка",
    screens: blogImg3,
    description: "OBBO Покупка",
    author: "OBBO Marketplace",
    thumb: "Fasion",
    create_at: "23 Окт, 2022",
    blogSingleImg: blogSingleImg3,
    comment: "95",
    blClass: "format-video",
    link: "https://www.youtube.com/embed/yKD4l13GMf8?si=i96hjTi3Ylcl30PR",
  },
];
export default blogs;
