import pimg1 from "../images/project/img-1.jpg";
import pimg2 from "../images/project/img-2.jpg";
import pimg3 from "../images/project/img-3.jpg";
import pimg4 from "../images/project/img-4.jpg";
import pimg5 from "../images/project/img-5.jpg";
import pimg6 from "../images/project/img-6.jpg";

import ps1img1 from "../images/project-single/1.jpg";
import ps1img2 from "../images/project-single/4.jpg";
import ps1img3 from "../images/project-single/5.jpg";
import ps1img4 from "../images/project-single/6.jpg";

import psub1img1 from "../images/project-single/2.jpg";
import psub1img2 from "../images/project-single/3.jpg";

const Projects = [
  {
    Id: "1",
    pImg: pimg1,
    ps1img: ps1img1,
    psub1img1: psub1img1,
    psub1img2: psub1img2,
    title: "OBBO Marketplace",
    subTitle: "Первый маркетплейс в Таджикистане",
    website: "https://obbo.tj",
  },
  {
    Id: "2",
    pImg: pimg2,
    ps1img: ps1img2,
    psub1img1: psub1img1,
    psub1img2: psub1img2,
    title: "AslZar",
    subTitle:
      "Сеть ювелирных домов и первый маркетплейс ювелирных украшений в Узбекистане",
    website: "http://aslzar.uz",
  },
  {
    Id: "3",
    pImg: pimg3,
    ps1img: ps1img3,
    psub1img1: psub1img1,
    psub1img2: psub1img2,
    title: "Kredit Market",
    subTitle: "Сеть магазинов электроники и бытовой техники",
    website: "https://kreditmarket.tj",
  },
  {
    Id: "4",
    pImg: pimg4,
    ps1img: ps1img4,
    psub1img1: psub1img1,
    psub1img2: psub1img2,
    title: "Babolo Group",
    subTitle:
      "Группа компаний, работающая в следующих направлениях: Babolo Education, Babolo taxi, Babolo Shop",
    website: "https://www.instagram.com/babolo_education/",
  },
  {
    Id: "4",
    pImg: pimg5,
    ps1img: ps1img4,
    psub1img1: psub1img1,
    psub1img2: psub1img2,
    title: "Международная холдинговая компания UDI International",
    subTitle: "Член совета директоров: UDI International, CEO UDI Tajikistan",
    website: "https://davlatov.world/?page_id=340",
  },
  {
    Id: "4",
    pImg: pimg6,
    ps1img: ps1img4,
    psub1img1: psub1img1,
    psub1img2: psub1img2,
    title: "Микрокредитная депозитная организация «Сандук»",
    subTitle: "Член совета директоров",
    website: "https://sanduk.tj/glavnaya/",
  },
];

export default Projects;
