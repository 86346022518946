import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Testimonials = [
  {
    name: "Камолиддин Ульмасов",
    title: "Специалист по маркетингу",
    descriptoion:
      "«Я выражаю глубокую благодарность Анвару Вайдуллаевичу за его важный вклад в мой профессиональный рост. Его услуги по развитию бизнеса обогатили меня ценными знаниями и инструментами, которые сыграли ключевую роль в достижении успеха моего бизнеса. Я настоятельно рекомендую его услуги всем, кто стремится к процветанию своего предприятия.»",
  },
  {
    name: "Зокиров Улугбек",
    title: "Владелица малого бизнеса",
    descriptoion:
      "«Анвар Вайдуллаевич не только выдающийся предприниматель, но и заботливый наставник, готовый поддержать своих учеников на каждом этапе их профессионального развития. Его мудрые советы и постоянная поддержка оказались неоценимыми для моего личного и делового роста.»",
  },
  {
    name: "Нодирбек Махмудов",
    title: "Предприниматель",
    descriptoion:
      "«Я воспользовался услугами Анвара Вайдуллаевича для консультации по стратегическому планированию моего бизнеса, и я был впечатлен его высоким уровнем профессионализма. Его экспертное сопровождение позволило мне разработать эффективную стратегию продвижения продукта и значительно увеличить объемы продаж. Я с уверенностью планирую обратиться к нему снова в будущем.»",
  },
  {
    name: "Кодиров Шахбоз",
    title: "Специалист по персоналу",
    descriptoion:
      "«Анвар Вайдуллаевич провел для нашей компании обучение по систематизации и стратегическому планированию бизнеса, и его выступление было просто великолепным! Его информативный, увлекательный и вдохновляющий спич позволил нам получить ценные знания, которые мы намерены успешно применить на практике. Мы глубоко признательны за этот опыт.»",
  },
];

const Testimonial = () => {
  var settings = {
    dots: false,
    arrows: false,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="tp-testimonial-section section-padding">
      <div className="container">
        <div className="tp-section-title">
          <span>Отзывы</span>
          <h2>Что говорят мои клиенты</h2>
        </div>

        <div className="tp-testimonial-wrap">
          <Slider {...settings}>
            {Testimonials.map((tstml, tsm) => (
              <div className="tp-testimonial-item" key={tsm}>
                <div className="tp-testimonial-text">
                  <p>{tstml.descriptoion}</p>
                  <span>{tstml.name}</span>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <div className="visible-rotate-text">
        <h1>Отзывы</h1>
      </div>
    </section>
  );
};

export default Testimonial;
