import React, { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import blogs from "../../api/blogs";
import BlogSingle from "../BlogSingle";
import { Link } from "react-router-dom";

const BlogSection = () => {
  const [open, setOpen] = React.useState(false);

  function handleClose() {
    setOpen(false);
  }

  const [state, setState] = useState({});

  const handleClickOpen = (item) => {
    // setOpen(true);
    // setState(item);
  };

  const [number, setCount] = useState(6);
  const [buttonActive, setButtonState] = useState(false);
  return (
    <section className="tp-blog-section section-padding" id="blog">
      <div className="container">
        <div className="tp-section-title">
          <span>Блог</span>
          <h2>Мои последние новости</h2>
        </div>
        <div className="tp-blog-items">
          <div className="row">
            {blogs.slice(0, number).map((blog, bl) => (
              <div className="col col-lg-4 col-md-6 col-12" key={bl}>
                <div className="tp-blog-item">
                  <div className="tp-blog-img">
                    {blog.link ? (
                      <iframe
                        width="375,5"
                        height="316"
                        src={blog.link}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                      ></iframe>
                    ) : (
                      <img src={blog.screens} alt="" />
                    )}
                    {/*<div className="thumb">{blog.thumb}</div>*/}
                  </div>
                  <div className="tp-blog-content">
                    <ul>
                      <li>{blog.create_at}</li>
                      <li>
                        от{" "}
                        <Link to="/" onClick={() => handleClickOpen(blog)}>
                          {blog.author}
                        </Link>
                      </li>
                    </ul>
                    <h2 onClick={() => handleClickOpen(blog)}>{blog.title}</h2>
                    <p>{blog.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div
            className={`sec-title-btn text-center mt-3 ${
              buttonActive ? "d-none" : ""
            }`}
          >
            <span onClick={() => setButtonState(!buttonActive)}>
              <button
                className="theme-btn"
                onClick={() => setCount(number + number)}
              >
                Просмотреть все новости
              </button>
            </span>
          </div>
        </div>
      </div>
      <div className="visible-rotate-text">
        <h1>Мой Блог</h1>
      </div>
      <BlogSingle
        open={open}
        onClose={handleClose}
        title={state.title}
        bImg={state.blogSingleImg}
        create_at={state.create_at}
        author={state.author}
        comment={state.comment}
      />
    </section>
  );
};

export default BlogSection;
