import React, { useState } from "react";
import SimpleReactValidator from "simple-react-validator";

const Contact = () => {
  const [forms, setForms] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [validator] = useState(
    new SimpleReactValidator({
      className: "errorMessage",
    })
  );
  const changeHandler = (e) => {
    setForms({ ...forms, [e.target.name]: e.target.value });
    if (validator.allValid()) {
      validator.hideMessages();
    } else {
      validator.showMessages();
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      validator.hideMessages();
      setForms({
        name: "",
        email: "",
        subject: "",
        message: "",
      });
    } else {
      validator.showMessages();
    }
  };

  return (
    <form
      onSubmit={(e) => submitHandler(e)}
      className="contact-validation-active"
    >
      <div className="row">
        <div className="col col-lg-6 col-md-6 col-12">
          <div className="form-field">
            <input
              className="form-control"
              value={forms.name}
              type="text"
              name="name"
              onBlur={(e) => changeHandler(e)}
              onChange={(e) => changeHandler(e)}
              placeholder="Ваше имя"
            />
          </div>
          {validator.message("name", forms.name, "required|alpha_space")}
        </div>
        <div className="col col-lg-6 col-md-6 col-12">
          <div className="form-field">
            <input
              className="form-control"
              value={forms.email}
              type="email"
              name="email"
              onBlur={(e) => changeHandler(e)}
              onChange={(e) => changeHandler(e)}
              placeholder="Ваш адрес электронной почты"
            />
            {validator.message("email", forms.email, "required|email")}
          </div>
        </div>
        <div className="col col-lg-12 col-12">
          <div className="form-field">
            <select
              className="form-control"
              onBlur={(e) => changeHandler(e)}
              onChange={(e) => changeHandler(e)}
              value={forms.subject}
              type="text"
              name="subject"
            >
              <option>Бизнес планирование</option>
              <option>Маркетинг и продажи</option>
              <option>Управление финансами</option>
              <option>Маркетинговый анализ</option>
            </select>
            {validator.message(
              "subject",
              forms.subject,
              "required|alpha_space"
            )}
          </div>
        </div>
        <div className="col fullwidth col-lg-12">
          <textarea
            className="form-control"
            onBlur={(e) => changeHandler(e)}
            onChange={(e) => changeHandler(e)}
            value={forms.message}
            type="text"
            name="message"
            placeholder="Сообщение"
          ></textarea>
          {validator.message("message", forms.message, "required")}
        </div>
      </div>
      <div className="submit-area">
        <button type="submit" className="theme-btn-s2">
          {" "}
          Отправить
        </button>
      </div>
    </form>
  );
};

export default Contact;
